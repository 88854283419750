























































































































import Vue from 'vue';
import LearningPathResourceViewModel from '@/src/services/viewModel/resource/LearningPathResourceViewModel';
import GetLearningPathUnitByLearningPathPagedListRequestViewModel from '@/src/services/viewModel/request/LearnitPaths/GetLearningPathUnitByLearningPathPagedListRequestViewModel';
import LearningPathUnitListResourceViewModel from '@/src/services/viewModel/resource/LearningPathUnitListResourceViewModel';
import DownloadLearningPathCertificateRequestViewModel from '@/src/services/viewModel/request/LearnitPaths/DownloadLearningPathCertificateRequestViewModel';
import LearningProgressResourceViewModel from '@/src/services/viewModel/resource/LearningProgressResourceViewModel';

export default Vue.extend({
  auth: true,
  data: () => ({
    learningPathContentDialogVisible: false,
    isLoadingButton: false,
    isLoading: true,
    isCertificatePrintable: false,
    learningPath: new LearningPathResourceViewModel(),
    learningUnits: [] as LearningPathUnitListResourceViewModel[],
    picturePath: '/img/learnit-unit-default.png',
    isUserVerified: false,
  }),
  computed: {
    isAdmin() {
      return this.$auth.isInRole('Admin', 'Hostadmin');
    },
    learningPathId() {
      const { learningPathId } = this.$router.currentRoute.params;
      if (learningPathId) {
        return parseInt(learningPathId, 10);
      }
      return undefined;
    },
  },
  async mounted() {
    this.isLoading = true;
    this.isCertificatePrintable = await this.isPrintCertificateDisabled();
    this.isUserVerified = this.$service.api.users.getHasAccessToPrivateContent();
    if (this.learningPathId) {
      this.learningPath = await this.$service.api.learnitPaths.getLearningPathById(this.learningPathId);
      const result = await this.$service.api.learnitPaths.getLearningPathUnitByLearningPathPagedList(this.learningPathId, new GetLearningPathUnitByLearningPathPagedListRequestViewModel());
      this.learningUnits = result.items;
    }
    // image
    if (this.learningPath.pictureDocumentId) {
      const blob = await this.$service.api.documents.downloadDocument(this.learningPath.pictureDocumentId);
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        this.picturePath = `${reader.result?.toString()}`;
      };
    }
    setTimeout(() => {
      this.isLoading = false;
    }, 100);
  },
  methods: {
    async picUrl(docId: number) {
      await this.$service.api.documents.downloadDocument(docId);
    },
    closeExternalLearningPathContent() {
      this.learningPathContentDialogVisible = false;
    },
    openExternalLearningPathContent() {
      this.learningPathContentDialogVisible = true;
    },
    async downloadLearningPath() {
      this.isLoadingButton = true;
      try {
        const request = new DownloadLearningPathCertificateRequestViewModel();
        const response = await this.$service.api.learnitPaths.downloadLearningPathCertificate(this.learningPathId!, request);
        const fileName = `Zertifikat-${this.learningPath.title}.pdf`;
        this.$service.utils.downloadFile(fileName, response);
      } catch (error) {
        this.$log.error(error);
      }
      this.isLoadingButton = false;
    },
    async startLearningPath() {
      const user = await this.$auth.getUser();
      const o = new LearningProgressResourceViewModel();
      o.entityId = this.learningPath.id;
      o.entityType = this.learningPath.learningPathEntityType;
      o.userId = user?.id!;
      if (this.learningPath.learningProgress === undefined || this.learningPath.learningProgress.statusPercentage !== 0) {
        await this.$service.api.learningProgress.setOrUpdateLearningProgress(o);
        this.learningPath = await this.$service.api.learnitPaths.getLearningPathById(this.learningPath.id!);
      }
    },
    async completeLearningPath() {
      const user = await this.$auth.getUser();
      const o = new LearningProgressResourceViewModel();
      o.entityId = this.learningPath.id;
      o.entityType = this.learningPath.learningPathEntityType;
      o.userId = user?.id!;
      o.statusPercentage = 100;
      await this.$service.api.learningProgress.setOrUpdateLearningProgress(o);
      this.learningPath = await this.$service.api.learnitPaths.getLearningPathById(this.learningPath.id!);
    },
    async isPrintCertificateDisabled() {
      const user = await this.$auth.getUser();
      const o = new GetLearningPathUnitByLearningPathPagedListRequestViewModel();
      o.userId = user?.id!;
      const learningUnits = await this.$service.api.learnitPaths.getLearningPathUnitByLearningPathPagedList(this.learningPathId!, o);
      let isDisabled = false;
      learningUnits.items.forEach((element) => {
        if (element.learningProgress === undefined || element.learningProgress.statusPercentage! < 100) {
          isDisabled = true;
        }
      });
      return isDisabled;
    },
  },
});
